import model from './model';
import { ProjectPageWidgetComponentIds as ComponentIds } from './config/constants';
import { getCustomViewerHttpClient } from '../../utils/viewerUtils';
import { getProjectBySlug, getProjectItems } from '../../services/dataServices';
import { reportPageViewed } from '../../utils/biUtils';
import {
  IProjectPageData,
  IProjectPageRouterData,
  ProjectItemsPagingDataType,
} from './config/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isEditor, isViewer } = flowAPI.environment;
  const { httpClient } = getCustomViewerHttpClient(flowAPI);
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();
  let components: ReturnType<typeof getComponents>;
  let routerData: IProjectPageRouterData = {};
  let projectPageData: IProjectPageData = {};
  let itemsPagingData: ProjectItemsPagingDataType = {};
  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    projectWidget1: $w(ComponentIds.ProjectWidget1),
    emptyStateWidget: $w(ComponentIds.EmptyStateWidget),
  });

  const initProjectPageData = async () => {
    const projectWidgetData = {
      project: projectPageData?.project,
      itemsData: { items: projectPageData?.projectItems, itemsPagingData },
      nextProjectSlug: projectPageData.nextProject?.slug || '',
      prevProjectSlug: projectPageData.previousProject?.slug || '',
    };
    components.projectWidget1.data = projectWidgetData;
  };

  const showEmptyStateIfNeeded = async () => {
    if (!isEditor) {
      return false;
    }
    let isEmptyStateDisplayed = false;
    if (routerData && !projectPageData?.project?.id) {
      await components.emptyStateWidget.expand();
      await components.projectWidget1.collapse();
      isEmptyStateDisplayed = true;
    } else if (routerData && projectPageData?.project?.id) {
      await components.emptyStateWidget.collapse();
      await components.projectWidget1.expand();
    }
    return isEmptyStateDisplayed;
  };

  const initData = async () => {
    routerData =
      (await flowAPI.controllerConfig.wixCodeApi.window.getRouterPublicData()) as IProjectPageRouterData;
    projectPageData = routerData?.projectPageData || {};
    if (projectPageData?.itemsPagingData) {
      itemsPagingData = projectPageData.itemsPagingData;
    }
    if (!projectPageData.project && isViewer) {
      projectPageData = await getProjectBySlug(flowAPI);
    }

    if (!projectPageData.projectItems && projectPageData.project?.id) {
      const { projectItems, pagingMetadataV2 } = await getProjectItems(
        httpClient,
        projectPageData.project.id,
      );
      projectPageData.projectItems = projectItems;
      itemsPagingData = pagingMetadataV2;
    }
  };

  const initProjectPage = async () => {
    await components.emptyStateWidget.collapse();
    await initData();
    const isEmptyStateDisplayed = await showEmptyStateIfNeeded();

    if (!isEmptyStateDisplayed) {
      await initProjectPageData();
    }
  };

  return {
    pageReady: async () => {
      components = getComponents();
      const isWidgetOnStage = !!components.topContainer.id;
      if (isWidgetOnStage) {
        await initProjectPage();

        reportPageViewed({ flowAPI, biLogger, pageName: 'project' });

        flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
          itemType: 'PORTFOLIO_PROJECTS',
          itemData: {
            project: projectPageData.project,
            projectItems: projectPageData.projectItems,
          },
          seoData: projectPageData?.project?.seoData || {},
        });
      }
    },
    exports: {},
  };
});
